import React from 'react'

import Page from '~/src/components/page'

const NotFoundPage = () => (
  <Page>
    <h1>
      NOT FOUND
    </h1>
  </Page>
)

export default NotFoundPage
